@import 'variables';

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}
  
.avatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
}

.name {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: $color-body;
}
.addButton{
  width: 200px;
}