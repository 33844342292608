.lable{
  position: absolute;
  width: 61px;
  height: 20px;
  left: 0px;
  top: 0px;
}

.lable2{
  font-family: San Francisco Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;  
}
.containerDiv{
  margin-left:100px;  
}

.rootdiv{
  width: 456px;
}