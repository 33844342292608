@import 'variables';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.contentContainer {
  padding: 48px;
  padding: 48px;
  background-color: $color-grey1;
  height: 100vh;
}
.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: none;
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: none;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;

    &--selected {
      background: #fff;
      border-bottom: solid 1px black;
      color: black;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

// Shared text styles
h2 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $color-grey2
}

.bodyNormal {
  font-size: 16px;
  line-height: 24px;
  color: $color-body1;
}

.bodyMedium {
  font-size: 14px;
  line-height: 20px;
  color: $color-body2;
}