@import 'variables';

.board {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 48px;
  background-color: $color-grey1;
  height: 100vh;

  .addStageBtn{
    width:264px;
    height:54px;
    color: #4B6668;
    border-style: dashed;
    border-color: #E5E5E5;
    background-color: transparent;
    font-weight: bolder;
    cursor: pointer;
    &:hover{
      background-color: #F2F2F2;
      color: #4B6668;
      font-weight: bolder;
    }
    &:active{
      background-color: #f8f7f7;
    }
    
  }
}
.centeredText {
  display: flex;
  align-items: center; 
  justify-content: center;
}

.plusIcon{
margin-right: 5px;
}
.stageCreation {
  background-color: transparent;
  padding: 10px; 
  margin: 10px; 

  input{
    width: 232px;
    height: 40px;
    &:focus{
      outline-color: #00ACED;
    }
  }
  button {
    padding: 8px 16px;
    background-color: transparent;
    color: #BDBDBD;
    font-size: 14px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }

  .addListBtn{
    background-color: #379587;
    color: #fff;   
    &:hover{
      background-color: #3fbdaa;
    }
    &:active{
      background-color: #379587;
    }
  }

  .addListBtn.disabled{
    width: 87px;
    height: 32px;
    background-color: #F2F2F2;
    color:#A0A0A0;
    font-size: 14px;
    font-weight: bold;
    cursor: not-allowed;
  }
}

.stageCreation div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.cancelBtn{
  &:hover{
    color:rgb(109, 108, 108)
  }
  &:active{
    color:rgb(163, 158, 158)
    
  }
}
