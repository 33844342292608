@import 'variables';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.24);
  z-index:9999;
}

.panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 456px;
  background-color: white;
  overflow-y: scroll;
}

.header {
  height: 220px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 32px;
  align-items: flex-start;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 40px;
  padding-bottom: 0px;
}

.cardTitle{
  color:#30403F;
  font-size:20px;
  font-weight:700;
}

.playerName {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: $color-display;
}

.playerStage {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: $color-body2;
}

.avatar {
  width: 104px;
  height: 104px;
  border-radius: 50%;
  overflow: hidden;
}

.tabHead{
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 80px; 
  border-bottom: .5px solid #E0E0E0;
  cursor: pointer;
  list-style: none;
  
  .inactiveTab {
    opacity: 0.5;
  }
  
}


.tabBody {
  padding-top: 8px;
  padding-bottom: 48px;
  border-bottom: .5px solid #E0E0E0;
  width: 100%;
  max-width: 456px;
}

.familyWrapper {
  padding-top: 8px;
  padding-bottom: 48px;
  width: 100%;
  max-width: 456px;
}

.playerInfo{
  margin-left: 24px;

}

.detailsColumns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 44px;
  border-bottom: .5px solid #E0E0E0;
}

.detailsColumn {
  margin-left: 24px;
  width:170px;
  flex: 1;
}

.videoSection{
  margin-left: 24px;
}

.textAreaWrap{
  border: 2px solid #F2F2F2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .textField {
    outline: none;
    width: 390px;
    height:100px;
    border:none;
    resize: none;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    
    .noteBtn{
      height: 24px;
      width: 75px;
      font-size:12px;
      font-weight: bold;
      border:none;
      border-radius: 4px;
      background-color: #F2F2F2;
      color: #A0A0A0;
      margin: 10px;
    }
  }
}



.experienceSection,.educationSection, .familySection, .notesSection{
  margin-left: 24px;
  


}

.noteContainer {
  border: 1px solid #E5E5E5;
  background-color: #F7F9F8;
  font-size:13px;
  border-radius: 2px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  .coachName {
    color:#4E5E5D;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .creationTime{
    color: #B0B0B0;
    font-weight: 400;
  }
  .note{
    font-weight: 400;
  }
}

