p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 15.0px Helvetica; color: #161516; -webkit-text-stroke: #161516}
p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; font: 15.0px Helvetica; color: #161516; -webkit-text-stroke: #161516; min-height: 18.0px}
p.p3 {margin: 0.0px 0.0px 0.0px 0.0px; font: 15.0px Helvetica; color: #161516; -webkit-text-stroke: #161516; background-color: #f6f6f6}
li.li1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 15.0px Helvetica; color: #161516; -webkit-text-stroke: #161516}
span.s1 {font-kerning: none; font-variant-ligatures: common-ligatures; background-color: #f6f6f6}
span.s2 {font-kerning: none; font-variant-ligatures: common-ligatures}
span.s3 {font-kerning: none; font-variant-ligatures: common-ligatures; color: #124f92; -webkit-text-stroke: 0px #124f92}
span.s4 {background-color: #f6f6f6; -webkit-text-stroke: 0px #000000}
ul.ul1 {list-style-type: none}
.container{
  padding:10px;
}