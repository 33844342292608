@import 'variables';

.container {
  padding-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width:200px;
  align-items: center;
  opacity:1;
}

.icon {
  width: 24px;
  height: 24px;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.labelText {
  font-size: 13px;
  line-height: 14px;
  color: $color-body1;
  font-weight: 600;
}

.subText {
  font-size: 13px;
  line-height: 13px;
  color: $color-body2;
  font-weight: 400;
}

.linkText{
  font-size: 13px;
  line-height: 18px;
  color: $color-link;
  font-weight: 400; 
}