@import 'variables';

.stage {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  width: 264px;
  background-color: $color-warmer-grey;
}

.header {
  padding-top: 16px;
  padding-bottom: 16px;
}

.title {
  font-weight: 700;
  color: $color-body;
  display: flex; 
  justify-content: space-between;
  align-items: center; 

}

.cardDrop {
  padding-bottom: 16px;
}

.cardPlaceholder {
  min-height: 64px;
  padding: 12px;
  border: 1px solid $color-divider;
  border-radius: 2px;
  background-color: $color-divider;
}

.dropDown{
  cursor: pointer;
}

.optionsContainer {
  position: relative;
  display: inline-block;
}

.optionsBox {
  position: absolute;
  top: 80px;
  left: -40px;
  transform: translateY(-50%);
  width: 112px;
  height: 80px;
  font-size: 11px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 4px;
  z-index: 1;
}

.option {
  cursor: pointer;
  padding: 4px 8px;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #e0e0e0;
}

.editingContainer {
  display: flex;
  align-items: center;
}

.editInput {
  margin-right: 10px;
  width:170px;
}

.deleteOption{
  color: #EB5757;
}

.saveButton{
  padding: 4px 14px;
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  border:none;
  border-radius: 4px;
  cursor:pointer;
  background-color: #379587;
  color: #fff;   
  &:hover{
    background-color: #3fbdaa;
  }
  &:active{
    background-color: #379587;
  }
}