@import 'variables';

.input {
  background: $color-warm-grey;
  height: 50px;
  width: 80%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
}
.input::placeholder  {
  color: $color-placeholder;
}

.label{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $color-disabled;
}