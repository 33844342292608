@import 'variables';


.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it's above other content
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border:1px solid #D9D9D9;
  border-radius: 2px;
  width: 320px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modalActions{
  display:flex;
  justify-content: center;
}
.closeButton {
  float: right;
  border: none;
  cursor: pointer;
}

.deleteButton, .cancelButton {
  margin: 10px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  height:32px;
  font-size:13px;
  font-weight: bold;
}

.deleteButton {
  background-color: #EB5757;
  color: white;
  width:97px;
  display: flex;
  align-items: center;
  text-align:center ;

  .deleteIcon{
    height: 20px;
    margin-right: 6px;
  }
}


.cancelButton {
  border:1px solid #d9d9d9;
  background-color: #fff;
  color: #3D4A49;
  width:76px;
}

// Adjust and extend styles as needed

.icon, .dropdown, .name, .avatar{
  cursor:pointer
}

.card {
  min-height: 64px;
  padding: 12px;
  border: 1px solid $color-divider;
  border-radius: 2px;
  &:hover{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  }
}

.cardBack {
  padding-bottom: 16px;
}

.content {
  background-color: $color-white;
}

.placeholder {
  margin-bottom: 16px;
  background-color: $color-divider;
}

.layout {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: flex-start;

  .wrapper1 {
    display: flex;
    justify-content: flex-end; 
  
    .playerDetailsWrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
    
      .playerInfoWrapper{
        display: flex;
      }
    
      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        border-radius: 50%;
        overflow: hidden;
      }
    
      .info {
        flex-grow: 1;
        font-size: 12px;
        color: $color-body;
        display: flex;
        width:120px;
        flex-direction: column;
      } 
      
      .wrapper2{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 4px;

        .wrapper3{
          display: flex;
          flex-direction: row;
        }
          .playerDetails {
            margin-right: 12px;
            color: #6A7877;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
      }
    }
  }

}










.name, .positions {
  margin-bottom: 4px; /* Adjust spacing as needed */
}

/* Ensure the .positions class is styled correctly to display */
.positions {
  font-size: 11px; /* You can adjust this as needed */
}

.name {
  font-size: 12px;
  font-weight: 600;
  color: $color-body;
}

.more {
  margin-left: 12px;
  width: 20px;
  height: 20px;
}

.icon{
  transform: rotate(90deg);
}


.dropdown {
  position: absolute;
  right: 1;
  top: 25%; 
  font-size: 12px;
  width: 112px;
  background-color: white;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 1;

  .options {
    text-align: center;
    padding: 6px;
    transition: background-color 0.2s;
    display: flex;
    align-items: left; 
    &:hover{
      background-color: #e0e0e0;
    }
  }
}

.moreWrapper {
  position: relative;
  display: inline-block;
}
.icons{
  color:#30403F;
  width:14px;
  height:14px;
  margin-right:4px;
}


.playerIcons{
  width:14px;
  margin-right:4px;
}

// .more {
//   cursor: pointer;
// }

// .icon {
//   width: 20px;
//   height: 20px;
// }

.moveSubMenu {
  display: none;
  position: absolute;
  left: 90%;
  top: 50%;
  z-index: 10000; 
  background: white;
  width:164px;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  
  &.open {
    display: block;
  }

  .inputWrapper{

    border-bottom: 1px solid #d9d9d9;
    height:44px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .searchInput{
    width: 148px;
    height: 32px;
    border:1px solid #d9d9d9;
  }

  }
  
  

  .moveOption{
    height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding-left: 6px;
    font-size: 14px;
    color:#3D4A49;
    
    &:hover{
      background-color: #e0e0e0;
    }

  }
}