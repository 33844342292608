@import 'variables';

.button {
  background-color: $color-emerald3;
  border-radius: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  &:hover{
    background-color:  rgb(126, 226, 209);
  }
  &:active{
    background-color: rgb(70, 128, 118);
  }
}