.body {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
}
.sidebar {
  height: 100%;
  width: 220px;
  min-width: 220px;  
  border-right: 1px solid rgb(232, 232, 232);
  padding-top: 20px;
}

.sidebarHeader {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.sidebarHeaderText {
  padding-left: 16px;
  vertical-align: text-bottom;
  font-weight: 600;
  font-size: 18px;
  color: rgb(75, 102, 104);
}

.poweredBy {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: auto;
  margin-bottom: 14px;
}

.poweredByText {
  margin-right: 4px;
  font-size: 13px;
  font-weight: 400;
  color: #A0A0A0;
}