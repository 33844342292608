@import 'variables';

.pic{
  width:80px;
  height:80px;
  border-color: black;
  background: #EEF0EF;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  padding:28px;
  margin-left:25px;
}
.picFrame{
  width:126px;
  align-items: center;
}
.loadedPic{
  width:80px;
  height:80px;
  border-color: black;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  margin-left:25px;
  display: block;
}
.dialogHeader{
  text-align: center;
  background-color: $color-emerald4;
  color: white;
  font-size: 14px;
  font-weight: 700;
}
.progressDiv{
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  text-align: center;  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.child {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}

.selectContainer{
  display:flex;
  justify-content: center;
}

.actionsContainer{
  margin: 0 auto;
}

.selectedFileContainer{
  margin:15px;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}