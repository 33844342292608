$color-body: rgba(75, 102, 104, 1);
$color-body1: rgba(78, 94, 93, 1);
$color-body2: rgba(78, 94, 93, 0.84);
$color-divider: rgba(232, 232, 232, 1);
$color-sidebar-selected: rgba(121, 206, 192, 0.16);
$color-warmer-grey: rgba(242, 242, 242, 1);
$color-warm-grey: rgba(249,248,247,1);
$color-white: rgba(255, 255, 255, 1);
$color-grey1: rgba(247, 249, 248, 1);
$color-grey2: rgba(79, 79, 79, 1);
$color-emerald3: rgba(55, 149, 135, 1);
$color-emerald4: rgba(121, 206, 192, 1);
$color-disabled: rgba(130,130,130,1);
$color-display: rgba(31, 41, 41, 1);
$color-placeholder: rgba(189,189,189,1);
$color-link: rgb(0, 112, 224);