.gridContainer{
  width:500px;
  margin-left:100px;
}

.pic{
  width:80px;
  height:80px;
  border-color: black;
  background: #EEF0EF;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  padding:28px;
  margin-left:25px;
}
.picFrame{
  width:126px;
  align-items: center;
}
.loadedPic{
  width:80px;
  height:80px;
  border-color: black;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  margin-left:25px;
  display: block;
}