@import 'variables';

.header {
  padding-bottom: 10px;
  border-bottom: 2px #eee solid;
}
.sidebarHeader {
  width: 300px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}
.mainHeader {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}
.headerImage{
  flex:1;
  max-width: 80px;
}
.headerText {
  flex-grow: 1;
  max-width: 800px;
  font-size: 32px;
  color: $color-body;
  font-weight: 700;
  min-width: 550px;
}

.sidebarHeaderText {
  padding-left: 16px;
  max-width: 150px;
  vertical-align: text-bottom;
  font-weight: 600;
  font-size: 18px;
  color: $color-body;
  flex:1;
}

.flexcontainer {
  display: flex;
}

.flexchild {
  flex: 1;
}  

.flexchild:first-child {
  margin-right: 20px;
  max-width: 250px;
} 